.screen--pc{
  width: 100vw;
  height: 100vh;
  overflow:hidden;
  background-color: #F2F9FF;
  background-image: url('../../images/views/landscape&pc/bg.png');
  background-size: 100% auto;
  background-position: 0 bottom;
  background-repeat: no-repeat;
  position: relative;
  header{
    padding-left: 69px;
    display: flex;
    justify-content: space-between;
    margin-top: 58px;
    .logo{
      width: 169px;
      height: 70px;
      background: url('../../images/views/landscape&pc/logo.png') no-repeat 0 0/ 100% auto;
    }
    .recharge{
      font-size: 20px;
      color: #000;
      letter-spacing: 0;
      display: none
    }
  }
  content{
    display: block;
    overflow:hidden;
  }
  .content{
    position: absolute;
    top: 75px;
    bottom: 119px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
    &.bigWind{
      transform: scale(1.5);
    }
    .conent__text{
      width: 435px;
      height: 402px;
      color: #FFA46D;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: -40px;
      .content__text-title{
        width: 324px;
        height: 124px;
        margin-bottom: 35px;
        background: url('../../images/views/landscape&pc/s_dian.png') no-repeat 0 50px/100% auto;
        background-size: contain;
        &.qiuqiu{
          background: url('../../images/views/landscape&pc/s_qiu.png') no-repeat 0 0/100% auto;
        }
        &.taikongsha{
          background: url('../../images/views/landscape&pc/s_tai.png') no-repeat 0 0/100% auto;
        }
      }
      .content__text-desc{
        font-size: 30px;
        letter-spacing: 0;
        margin-bottom: 77px;
      }
      .download-btns{
        display: flex;
        .btn{
          position: relative;
          width: 162px;
          height: 59px;
          margin-right: 20px;
          opacity: 0.9;
          &.btn--app-store{
            background: url('../../images/btn-download-app-store.png') no-repeat 0 0/100% auto;
          }
          &.btn--google-play{
            background: url('../../images/btn-download-google-play.png') no-repeat 0 0/100% auto;
          }
          &:hover{
            opacity: 1;
          }
        }
      }
      .btn-beat-icon-pc {
        position: absolute;
        top: -10px;
        right: -5px;
        width: 38px;
        height: 16px;
        background-color: #FF054D;
        border-radius: 8px 8px 8px 2px;
        border: 0.01rem solid #E6FAF9;
        color: #FFF;
        font-size: 10px;
        font-weight: 600;
        text-align: center;
        line-height: 16px;
      }
    }
    .conent__image{
      height: 100%;
      width: 470px;
      background: url('../../images/views/landscape&pc/b_dian.png') no-repeat 0 center;
      background-size: contain;
      &.qiuqiu{
        background: url('../../images/views/landscape&pc/b_qiu.png') no-repeat 0 center;
        background-size: contain;
      }
      &.taikongsha{
        background: url('../../images/views/landscape&pc/b_tai.png') no-repeat 0 center;
        background-size: contain;
      }
    }
  }
  footer{
    background-color: #fff;
    height: 68px;
    padding-top: 22px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .brand{
      text-align: center;
      margin: 0 auto;
      font-size: 14px;
      color: #000;
      margin-bottom: 7px;
      font-weight: bold;
    }
    .copyright{
      color: #000;
      font-size: 14px;
      .links{
        text-align: center;
        opacity: 0.4;
        font-weight: bold;
        .link{
          font-size: 14px;
          color: #000;
          letter-spacing: -0.23px;
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
        }
        .link:first-child{
          margin-left: 0;
        }
      }
    }
  }
}