.screen--portrait{
  width: 100vw;
  height: 100vh;
  max-width: 450px;
  margin: 0 auto;
  background-image: url('../../images/views/portrait/bg.png');
  background-repeat: no-repeat;
  background-position: 0 -.68rem;
  background-size: 100% auto;
  padding-top: 0.43rem;
  header{
    padding-left: 0.19rem;
    .logo{
      width: 1.02rem;
      height: 0.43rem;
      margin-bottom: 0.1rem;
      background: url('../../images/views/portrait/logo.png') no-repeat 0 0/ 100% auto;
    }
  }
  .phone-bg{
    position: relative;
    margin: 0 auto;
    width: 3.45rem;
    height: 5.26rem;
    background: url('../../images/views/portrait/m_dian.png') no-repeat center 0/auto 100%;
    margin-bottom: 0.06rem;
    &.qiuqiu{
      background: url('../../images/views/portrait/m_qiu.png') no-repeat center 0/auto 100%;
    }
    &.taikongsha{
      background: url('../../images/views/portrait/m_tai.png') no-repeat center 0/auto 100%;
    }
  }
  .download-btn{
    width: 3.1rem;
    height: 0.5rem;
    margin: 0 auto .36rem;
    background: url('../../images/views/portrait/btn-download.png') no-repeat 0 0/ 100% auto;
  }
  footer{
    height: .38rem;
    background-color: #fff;
    padding: 0.2rem 0;
    .brand{
      width: 100%;
      color: #000;
      font-size: 0.14rem;
      line-height: .2rem;
      text-align: center;
      font-weight: bold;
      margin-bottom: 0.04rem;
    }
    .links{
      text-align: center;
      font-size: 0.11rem;
      line-height: .14rem;
      opacity: 0.4;
      color: #000;
      // font-weight: bold;
      .link{
        display: inline-block;
        margin-bottom: 0.1rem;
        text-align: center;
        color: #000;
        text-decoration: unset;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
}